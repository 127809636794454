import React, { useState } from "react"
import Layout from "../layout"
import Header from "../../sections/header"
import classNames from "classnames"
import { Link } from "gatsby"
import "./styles.css"
import Footer from "../../sections/footer"

const PolicyLayout = ({ children }) => {
  const [openMenu, setOpenMenu] = useState()

  const toggle = () => {
    setOpenMenu(!openMenu)
  }

  return (
    <Layout>
      <div className="policy-layout">
        <Header />

        <div className="wt-margin-bottom-75">
          <div className="container">
            <div className="policy-layout__content">
              <div
                className={classNames("policy-layout__nav", {
                  "policy-layout__nav--open": openMenu,
                })}
              >
                <div className="policy-layout__nav-title">Policy</div>
                <Link
                  to="/terms"
                  activeClassName="policy-layout__nav-item--active"
                  className="policy-layout__nav-item"
                >
                  Terms and Conditions
                </Link>
                <Link
                  to="/privacy"
                  activeClassName="policy-layout__nav-item--active"
                  className="policy-layout__nav-item"
                >
                  Privacy policy
                </Link>
                <Link
                  to="/contacts"
                  activeClassName="policy-layout__nav-item--active"
                  className="policy-layout__nav-item"
                >
                  Contacts
                </Link>
              </div>
              <div className="policy-layout__info">{children({ toggle })}</div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </Layout>
  )
}

export default PolicyLayout
